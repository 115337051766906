import * as React from 'react'
import styled from 'styled-components'
import Container from '../shared/Full'
import AppendixContent from '../shared/AppendixContent'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import ogImg from '../images/appendix.png'
import AppendixLoops from '../shared/AppendixLoops'

const TableOfContents = () => {

    const Table = styled.nav`
        position: sticky;
        background: #282828;
        padding: 20px 0px;
        border-radius: 10px;
        top: 100px;
        width: 100%;
        float: right;

        & h2 {
            color: #FDF9F0;
            padding-left: 25px;
        }

        & .link {
            color: #FDF9F0;
            text-decoration: none;
            font-size: 24px;
        }
    `; 

    const Border = styled.div`
        width: 100%;
        height: 1px;
        margin: 10px 0px;
        background: #FDF9F0;
    `;

    const List = styled.ul`
        padding-left: 25px;
        margin-left: 25px;
    `;

    const Item = styled.li`
        color: #FDF9F0;
        margin-bottom: 15px;
        padding-left: 25px;
        list-style: none;
    `;

    return(
        <>
            <Table aria-label="Table of contents">
                <List>

                </List>
                <Item><Link className="link" to="#1964">60-talet</Link></Item>
                <Item><Link className="link" to="#1972">70-talet</Link></Item>
                <Item><Link className="link" to="#1980">80-talet</Link></Item>
                <Item><Link className="link" to="#1990">90-talet</Link></Item>
                <Item><Link className="link" to="#2000">00-talet</Link></Item>
                <Item><Link className="link" to="#2010">10-talet</Link></Item>
                <Item><Link className="link" to="#2020">20-talet</Link></Item>
                <Item><Link className="link" to="#Böcker">Böcker</Link></Item>
                <Item><Link className="link" to="#Konst">Konst</Link></Item>
                <Item><Link className="link" to="#Föreställningar">Föreställningar</Link></Item>
                <Item><Link className="link" to="#Tv">Tv</Link></Item>
                <Item><Link className="link" to="#Radio">Radio</Link></Item>
                <Item><Link className="link" to="#Biofilmer">Biofilmer</Link></Item>
                <Item><Link className="link" to="#Scen">Scen</Link></Item>
                <Item><Link className="link" to="#Musik">Musik</Link></Item>
                <Item><Link className="link" to="#Tidningar/Tidskrifter">Tidningar</Link></Item>
                <Item><Link className="link" to="#Övrigt">Övrigt</Link></Item>
                <Item><Link className="link" to="#Utmärkelser">Utmärkelser</Link></Item>
                
            </Table>
        </>
    )
}

const Appendix = () => {

    const PageContainer = styled.div`
        padding-top: 200px;
        padding-left: 10px;
        padding-right: 10px;
        max-width: 1200px;
        width: 100%;
        height: auto;
        margin: 0 auto;
        postition: relative;
        display: flex; 
        flex-direction: row;
        flex-wrap: wrap; 
        justify-content: center;
        align-items: stretch;
        align-content: center;
        @media only screen
            and (min-width: 980px)
            and (max-width: 1400px) {
                max-width: 980px;
            }
        @media only screen
        and (min-width: 720px)
        and (max-width: 980px) {
            max-width: 700px;
        }
        @media only screen
        and (min-width: 580px)
        and (max-width: 720px) {
            width: 100%;
        }
    `;

    const Row = styled.div`
        width: 100%;
        height: auto;
        display: inline-flex;
        @media only screen
            and (min-width: 320px)
            and (max-width: 980px) {
                flex-direction: column;
            }
    `;

    const Columns75 = styled.div`
        width: 75%;
        height: auto;

        @media only screen
            and (min-width: 320px)
            and (max-width: 980px) {
                width: 100%;
            }
    `;

    const Columns25 = styled.div`
        width: 25%;
        height: auto;
        position: relative;
        @media only screen
            and (min-width: 320px)
            and (max-width: 980px) {
                width: 100%;
            }
    `;

    return(
        <Container>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Appendix - Rolf Börjlind</title>
                <meta name="description" content="Rolfs historia i punktform." />
                 <meta property="og:image" content={ogImg} />
            </Helmet>
            <PageContainer>
            <Row>
            <Columns25>
                <TableOfContents />
            </Columns25>
                <Columns75>
                    <h1 id="initial-header">Kronologisk verksamhetsberättelse</h1>
                    {/* <AppendixContent /> */}
                    <AppendixLoops />
                </Columns75>
            </Row>
            </PageContainer>
        </Container>
    )

}

export default Appendix