import * as React from 'react'
import styled from 'styled-components'
import AllYears from '../pages/data/appendix.json'
import Books from '../pages/data/books.json'
import Konst from '../pages/data/konst.json'
import Forestallningar from '../pages/data/föreställningar.json'
import Tv from '../pages/data/tv.json'
import Radio from '../pages/data/radio.json'
import Biofilmer from '../pages/data/bio.json'
import Musik from '../pages/data/musik.json'
import Scen from '../pages/data/scen.json'
import Tidningar from '../pages/data/tidningar.json'
import Misc from '../pages/data/övrigt.json'
import Utm from '../pages/data/utmärkelser.json'

const Border = styled.div`
        width: 100%;
        height: 1px;
        margin: 10px 0px;
        background: #FDF9F0;
    `;

    const Title = styled.h2`
        width: 100%;
        font-size: 24px;
    `;

    const Redo = styled.h3`
        padding: 30px 0px 12px;
    `;

    const Divider = styled.div`
        width: 100%;
        height: 100px;
    `;

const Years = (data) => {

    const years = data.data

    return(
        <>


            {
                years.map(
                    outer => {
                        return (
                            <>
                            <Divider />
                                {
                                  outer.map(
                                      item => {
                                        if(item.year) return(
                                            <>
                                                <Title key={item.year} id={item.year}>{item.year}</Title> 
                                                <Border />
                                            </>
                                        )
                                        if(item.redovisat) return(<Redo>{item.redovisat}</Redo>)
                                        return(<p>{item.text}</p>)
                                      }
                                  )  
                                }
                            </>
                        )
                    }
                )
            }
        </>
    )

}

const Other = ({data, Title, SubTitle}) => {
    return(
        <>  
            <Divider />
            <h2 id={Title}>{Title}</h2>
            <h3>1964 - 2021</h3>
            <p>{SubTitle}</p>
            <Border />
            <>
            {
                data.map(
                    result => {
                        return(<p>{result.text}</p>)
                    }
                )
            }
            </>
        </>
    )
}

const AppendixLoops = () => {

    const Border = styled.div`
        width: 100%;
        height: 1px;
        margin: 10px 0px;
        background: #FDF9F0;
    `;

    const Divider = styled.div`
        width: 100%;
        height: 100px;
    `;

    return(
        <>
            <Years data={AllYears} />
            <Other data={Books} Title="Böcker" SubTitle="" />
            <Other data={Konst} Title="Konst" SubTitle="utställningar/mappar/videofilmer/katalogtexter" />
            <Other data={Forestallningar} Title="Föreställningar" SubTitle="multimedia/musik/poesi" />
            <Other data={Tv} Title="Tv" SubTitle="filmer/serier/program" />
            <Other data={Radio} Title="Radio" SubTitle="pjäser/program" />
            <Other data={Biofilmer} Title="Biofilmer" SubTitle="" />
            <Other data={Scen} Title="Scen" SubTitle="pjäser/kabaréer/revyer" />
            <Other data={Musik} Title="Biofilmer" SubTitle="sångtexter/skivor" />
            <Other data={Tidningar} Title="Tidningar/Tidskrifter" SubTitle="tidningar/tidskrifter" />
            <Other data={Misc} Title="Övrigt" SubTitle="" />
            <Other data={Utm} Title="Utmärkelser" SubTitle="" />
            <Divider />
        </>
    )
}

export default AppendixLoops