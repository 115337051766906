import * as React from 'react'
import styled from 'styled-components'
import {useEffect, useState} from 'react'

const Simulera = styled.div`
        min-height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap; 
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        position: relative;
        padding: 50px 0px;

        & h1 {
            font-size: 54px;
        }

        & h2 {
            font-size: 34px;
        }

        & h3 {
            font-size: 24px;            
        }

        & p {
            font-size: 18px;
            margin-bottom: 0px;
            margin-top: 0px;
            line-height: 28px;
        }

        & .stars {
            font-size: 34px;
            text-align: center;
            margin: 22px 0px 22px;
            color: #FDF9F0;
        }

        @media only screen
        and (min-width: 320px)
        and (max-width: 720px) {
            & h1 {
                font-size: 34px;
            }
    
            & h2 {
                font-size: 24px;
            }
    
            & h3 {
                font-size: 20px;            
            }
        }

    `;



const PoemLoop = (data) => {

  const poems = data.data

    return(
      <>
      {
        poems.map(
          poem => {
            return (
              <Simulera>
                <div className="stars">***</div>
              {
                poem.map(
                  row => {
                    if (row === '\n') return(<br />)
                    return(<p key={row.length}>{row}</p>)
                  }
                )
              }
              </Simulera>
            )
          }
        )
      }
      </>
    )

}

export default PoemLoop
